<template>
  <the-header></the-header>
  <div class="wrapper"><router-view /></div>
</template>

<script>
import TheHeader from "./components/UI/TheHeader.vue";
export default {
  components: { TheHeader },
};
</script>

<style lang="scss">
@import "_base.scss";
body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  width: 100%;
  color: $black;
  padding-bottom: 1rem;
}
#app {
  font-family: "Noto Sans", sans-serif;
  .wrapper {
    width: 95%;
    margin: 0 auto;
  }
  a {
    color: inherit;
    text-decoration: none;
    &:visited {
      color: inherit;
      text-decoration: none;
    }
  }
}
</style>
