import { createStore } from "vuex";

export default createStore({
  state: {
    offer: {
      name: "",
      desc: "",
      items: [],
      taxVal: 8.5,
    },
  },
  getters: {
    getOffer(state) {
      return state.offer;
    },
    getOfferValue(state) {
      const itemZeroValue = state.offer.items.find(
        (item) => item.quantity * item.price === 0
      );
      if (itemZeroValue) {
        return false;
      }
      return true;
    },
  },
  mutations: {
    setOffer(state, payload) {
      state.offer.items = [...payload.items];
      state.offer.taxVal = payload.taxVal;
    },
  },
  actions: {
    setOffer(context, payload) {
      context.commit("setOffer", payload);
    },
  },
  modules: {},
});
