<template>
  <div class="overlay">
    <div class="dialog">
      <header>
        <h4><slot name="header"></slot></h4>
      </header>
      <div>
        <p><slot name="desc"></slot></p>
      </div>
      <div class="flex-group">
        <base-button @click="onSubmitEvent" :btnType="'submit'" v-if="submit">{{
          onSubmit
        }}</base-button>
        <base-button @click="onRemoveEvent" :btnType="'remove'" v-if="remove">
          {{ onRemove }}</base-button
        >
        <base-button
          @click="onPrimaryEvent"
          :btnType="'primary'"
          v-if="primary"
        >
          {{ onPrimary }}
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    submit: {
      type: Boolean,
      default: false,
    },
    remove: {
      type: Boolean,
      default: false,
    },
    primary: {
      type: Boolean,
      default: false,
    },
    onSubmit: {
      type: String,
      required: false,
    },
    onRemove: {
      type: String,
      required: false,
    },
    onPrimary: {
      type: String,
      required: false,
    },
  },
  methods: {
    onSubmitEvent() {
      this.$emit("onSubmitEvent");
    },
    onRemoveEvent() {
      this.$emit("onRemoveEvent");
    },
    onPrimaryEvent() {
      this.$emit("onPrimaryEvent");
    },
  },
};
</script>

<style lang="scss" scoped>
.overlay {
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.dialog {
  position: absolute;
  background: white;
  left: calc(50% - 200px);
  top: 25%;
  padding: 20px;
  width: 400px;
  border-radius: 8px;
  h4 {
    text-align: center;
  }
  .flex-group {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>