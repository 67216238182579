<template>
  <nav>
    <router-link class="badge" to="/">Riczer</router-link>
    <ul>
      <router-link to="/calc" custom v-slot="{ href, navigate, isActive }">
        <li :class="{ active: isActive }" :active="isActive" @click="navigate">
          <a :href="href">Kalkulator</a>
        </li>
      </router-link>
      <router-link to="/offers" custom v-slot="{ href, navigate, isActive }">
        <li :class="{ active: isActive }" :active="isActive" @click="navigate">
          <a :href="href">Oferty</a>
        </li>
      </router-link>
    </ul>
  </nav>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "@/_base.scss";
nav {
  width: 100%;
  padding: 0px 20px;
  display: flex;
  background: $yellow;
  color: $white;
  .badge {
    display: flex;
    align-items: center;
  }
  ul {
    margin: 0;
    display: flex;
    height: 40px;
    align-items: center;
    li {
      display: flex;
      align-items: center;
      height: 100%;
      margin-left: 1rem;
      list-style: none;
      padding: 0 10px;
      cursor: pointer;
      &:hover {
        color: $grey;
      }
      &.active {
        background: $white;
        color: $yellow;
      }
    }
  }
}
</style>