<template>
  <div class="card">
    <header class="header"><slot name="header"></slot></header>
    <div class="content"><slot name="content"></slot></div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../_base.scss";
.card {
  //border: 2px solid $grey;
  box-shadow: 0px 0px 10px 2px rgb(0 0 0 / 10%);
  border-radius: 6px;
  padding: 1rem;
  background: $white;
}
</style>