<template>
  <button :class="btnType"><slot></slot></button>
</template>

<script>
export default {
  props: ["btnType"],
};
</script>

<style lang="scss" scoped>
@import "../../_base.scss";

button {
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  &.remove {
    background: $white;
    border: 2px solid $red;
    padding: 0.1rem 0.4rem;
    color: $red;
    &:hover {
      background: $red;
      color: $white;
    }
  }
  &.primary {
    background: $yellow;
    color: $white;
    &:hover {
      background: $yellow-shade;
    }
  }
  &.submit {
    background: $grey;
    &:hover {
      opacity: 0.9;
    }
  }
}
</style>