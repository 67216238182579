import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// base components
import BaseCard from "./components/base/BaseCard";
import BaseButton from "./components/base/BaseButton";
import BaseDialog from "./components/base/BaseDialog";

createApp(App)
  .component("base-card", BaseCard)
  .component("base-button", BaseButton)
  .component("base-dialog", BaseDialog)
  .use(store)
  .use(router)
  .mount("#app");
