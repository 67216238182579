import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/calc",
    name: "Calculator",
    component: () => import("../views/Calculator.vue"),
  },
  {
    path: "/offers",
    name: "Offers",
    component: () => import("../views/Offers.vue"),
  },
  {
    path: "/summary",
    name: "Summary",
    component: () => import("../views/Summary.vue"),
  },
  {
    path: "/riczer_dev/:catchAll(.*)",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
